"use client";

import { useEffect } from "react";

interface ITrustpilotWidgetProps {
  style?: React.CSSProperties; // Optional inline styles
}
const TrustpilotWidget = ({
  style = {},
}: ITrustpilotWidgetProps): JSX.Element => {
  useEffect(() => {
    // Dynamically load the Trustpilot widget script
    const script = document.createElement("script");
    script.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="trustpilot-widget d-flex justify-content-center"
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="600359c87a637900014237da"
      data-style-height="120px"
      data-style-width="60%"
      style={{
        ...style,
      }}
    >
      <a
        href="https://uk.trustpilot.com/review/www.mygwork.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default TrustpilotWidget;
