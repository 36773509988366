import LZString from "lz-string";
import { Dispatch, SetStateAction, useCallback, useContext } from "react";

import { CookieKeys } from "@/enums/CookieKeys.enum";
import { useCookies } from "@/hooks/useCookies";
import {
  AuthContext,
  IUserDateContext,
  UpdateAuthContext,
} from "@/src/providers/AuthProvider";
import { brazeWipeData, changeBrazeUser } from "@/utils/brazeClient";

import { isUserAnOrganizationAdmin } from "../utils/organization";

import { LoginResponse } from "./api/generated";
import { useNavigate } from "./useNavigate";

interface IUseAuth {
  isAuthenticated: boolean | undefined;
  authData?: LoginResponse;
  signOut: () => void;
  clearUserData: () => void;
  handleImpersonateSignin: (
    impersonateLoginDat: LoginResponse,
    source?: string
  ) => void;
  setAuthData?: Dispatch<SetStateAction<IUserDateContext | undefined>>;
}

export function useAuth(): IUseAuth {
  const authData = useContext(AuthContext);
  const setAuthData = useContext(UpdateAuthContext);
  const { setCookie } = useCookies();

  const { removeCookie } = useCookies();
  const { push } = useNavigate();

  const handleImpersonateSignin = useCallback(
    (impersonateLoginData: LoginResponse, source?: string): void => {
      setAuthData?.({ data: impersonateLoginData, isAuthenticated: true });

      if (typeof window !== "undefined") {
        window.localStorage.setItem(
          CookieKeys.UserData,
          LZString.compressToBase64(JSON.stringify(impersonateLoginData))
        );
      }
      changeBrazeUser(impersonateLoginData);
      setCookie({
        key: CookieKeys.UserData,
        value: LZString.compressToBase64(JSON.stringify(impersonateLoginData)),
      });
      setCookie({ key: CookieKeys.Email, value: impersonateLoginData?.email });
      setCookie({
        key: CookieKeys.IsEmailVerified,
        value: impersonateLoginData?.verifiedAt ? "true" : "false",
      });
      setCookie({ key: CookieKeys.Type, value: impersonateLoginData?.type });
      setCookie({
        key: CookieKeys.AccessToken,
        value: impersonateLoginData.accessToken,
      });
      setCookie({
        key: CookieKeys.RefreshToken,
        value: impersonateLoginData.refreshToken,
      });

      if (isUserAnOrganizationAdmin(impersonateLoginData.userTaxonomy)) {
        const isImpersonateViaCopy = source && source[0];
        push(
          isImpersonateViaCopy
            ? `/organizations/${impersonateLoginData.userTaxonomy?.organization?.slug}`
            : "/profile/organization-profile"
        );
      } else {
        push("/profile/home");
      }
    },
    [push, setAuthData, setCookie]
  );

  // clearUserData function
  const clearUserData = useCallback(() => {
    [
      CookieKeys.AccessToken,
      CookieKeys.RefreshToken,
      CookieKeys.UserData,
      CookieKeys.IsEmailVerified,
      CookieKeys.Type,
      CookieKeys.Email,
      CookieKeys.EmailCollect,
      CookieKeys.EmailCollectClose,
      CookieKeys.Redirect,
    ].forEach((key) => removeCookie(key));
    brazeWipeData();
    setAuthData?.({ data: undefined, isAuthenticated: false });
  }, [removeCookie, setAuthData]);

  // SignOut function
  const signOut = useCallback(() => {
    clearUserData();
    push("/");
  }, [push, clearUserData]);

  return {
    isAuthenticated: authData?.isAuthenticated,
    authData: authData?.data,
    handleImpersonateSignin,
    clearUserData,
    signOut,
    setAuthData,
  };
}
